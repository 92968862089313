@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, "primary");

  app-settlement-dashboard {
    --settlement-dashboard-value-highlight-color: #{mat.get-color-from-palette($primary-palette, "default")};
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  app-settlement-dashboard {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
