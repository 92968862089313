@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $foreground-palette: map.get($color-config, "foreground");
  $background-palette: map.get($color-config, "background");

  app-alert {
    --background: #{mat.get-color-from-palette($background-palette, "dialog")};
    --color: #{mat.get-color-from-palette($foreground-palette, "text")};
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  app-alert {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
