// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@use "./alert/alert-theme" as alert;
@use "./app/components/order-list/order-list-theme" as order-list;
@use "./app/components/user-list/user-list-theme" as user-list;
@use "./app/pages/sales-dashboard/sales-dashboard-theme" as sales-dashboard;
@use "./app/pages/settlement-dashboard/settlement-dashboard-theme" as settlement-dashboard;
@use "./app/pages/sidenav/sidenav-theme" as sidenav;
@use "./global" as global;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$celebhere-backoffice-client-primary: mat.define-palette(mat.$indigo-palette);
$celebhere-backoffice-client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$celebhere-backoffice-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$celebhere-backoffice-client-theme: mat.define-light-theme(
  (
    color: (
      primary: $celebhere-backoffice-client-primary,
      accent: $celebhere-backoffice-client-accent,
      warn: $celebhere-backoffice-client-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($celebhere-backoffice-client-theme);
@include alert.theme($celebhere-backoffice-client-theme);
@include order-list.theme($celebhere-backoffice-client-theme);
@include sales-dashboard.theme($celebhere-backoffice-client-theme);
@include settlement-dashboard.theme($celebhere-backoffice-client-theme);
@include sidenav.theme($celebhere-backoffice-client-theme);
@include user-list.theme($celebhere-backoffice-client-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@include global.theme($celebhere-backoffice-client-theme);

// 커스텀 전역 스타일
a.app-link-deleted:link,
a.app-link-deleted:visited {
  text-decoration: line-through;

  &:hover {
    text-decoration: line-through underline;
  }
}
