@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $warn-palette: map.get($color-config, "warn");
  $foreground-palette: map.get($color-config, "foreground");

  app-user-list {
    --user-list-deleted-at-color: #{mat.get-color-from-palette($warn-palette, "default")};
    --user-list-sign-in-email-color: #{mat.get-color-from-palette($foreground-palette, "secondary-text")};
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  app-user-list {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
