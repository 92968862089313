@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $warn-palette: map.get($color-config, "warn");

  app-order-list {
    --order-list-amount-canceled-color: #{mat.get-color-from-palette($warn-palette, "default")};
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  app-order-list {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
